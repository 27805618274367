import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Loading from "./Loading";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import LoadingSpinner from "./LoadingSpinner";
import { useWidthScreen } from "helpers/hooks/useWidthScreen";
type TSize = "small" | "normal" | "full";
type TSizeText = {
  [K in TSize]: string;
};
interface NextStepProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  isLoading?: boolean;
  icon?: React.ReactNode;
  background?: "normal" | "blue" | "gray";
  size?: TSize;
  typeStep?: "fill" | "border";
}
const NextStep = ({
  text,
  isLoading = false,
  icon,
  background = "normal",
  size = "normal",
  typeStep = "fill",
  ...props
}: NextStepProps) => {
  const { t } = useTranslation();
  const { width } = useWidthScreen();
  let sizeText: TSizeText = {
    normal: "size-24",
    small: "size-24",
    full: "size-24",
  };
  if (width > 767) {
    sizeText = {
      normal: width > 767 ? "size-1" : "size-24",
      small: "size-1",
      full: "size-1",
    };
  }
  return (
    <Wrap
      className={` size-1 ${size} ${background} type-${typeStep} ${
        isLoading ? "loading" : ""
      }`}
      disabled={isLoading}
      {...props}
    >
      {isLoading ? (
        <span>
          <LoadingSpinner />
          {/* <Loading relative size="tiny" /> */}
        </span>
      ) : (
        <span
          className={`${sizeText[size]} ${
            icon ? "contain-icon" : "contain-text"
          }`}
        >
          {t(text)} {icon ? <span className="btn-icon">{icon}</span> : null}
        </span>
      )}
    </Wrap>
  );
};

export default NextStep;
const Wrap = styled.button`
  background: var(--primary-green-200);
  padding: 8px 16px;
  border-radius: 5px;
  position: relative;
  min-width: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  z-index: 1;
  background: linear-gradient(45deg, #1eaa43 0%, #fff);
  span {
    position: relative;
    z-index: 4;
  }
  :before {
    content: "";
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background: var(--primary-green-200);
    position: absolute;
    left: 1px;
    top: 1px;
    z-index: 2;
    border-radius: 5px;
  }

  &.gray {
    background: var(--secondary-button-bg-secondary);
    color: var(--white);
    .contain-icon,
    .contain-text {
      color: var(--white);
    }
    :before {
      background: var(--secondary-button-bg-secondary);
    }
    :hover,
    :active {
      background: var(--primary-green-400) !important;
      color: #fff;
      :before {
        background: var(--primary-green-400) !important;
      }
    }
  }
  .contain-icon {
    display: flex;
    align-items: center;
    margin: -3px 0;
  }
  .btn-icon {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }
  .contain-icon,
  .contain-text {
    color: var(--grey-900, #2e2e2e);
    white-space: nowrap;
  }

  &.loading {
    span {
      > div {
        position: static;
      }
    }
  }
  :hover,
  :active {
    background: var(--primary-green-400) !important;

    .contain-icon,
    .contain-text {
      color: #fff;
    }
  }
  :disabled {
    opacity: 0.4;
    cursor: no-drop;
  }
  //size full
  &.full {
    min-width: 100%;
  }
  //type border
  &.type-border {
    background: transparent;
    border: 1px solid var(--primary-green-100);
    :before {
      background: transparent;
    }
    .contain-icon,
    .contain-text {
      background: transparent;
      color: var(--primary-green-100);
    }
    :hover,
    :active {
      border: 1px solid var(--primary-green-400);
      background: var(--primary-green-200) !important;

      .contain-icon,
      .contain-text {
        color: #fff;
        .btn-icon {
          svg path {
            fill: #fff;
          }
        }
      }
    }
  }
  ${breakpointsMedias.min640} {
    padding: 12px 24px;
    min-width: 180px;
    &.small {
      padding: 6px 16px;
      min-width: 140px;
    }
    &.full {
      min-width: 100%;
    }
    .contain-icon {
      margin: 0;
    }
  }
`;
